<template>
  <DefaultTemplate>
    <div class="depositFunds-box">
      <div class="form-left">
        <div class="content">
          <div class="info-box">
            <p>{{ $t('deposit.localdepositor.depositor') }}</p>
            <DepositInfo :instData="instData" :showNote="showNote"></DepositInfo>
            <p class="title">
              {{ $t('deposit.method.form.header', { method: $t('deposit.localdepositor.depositor') }) }}
            </p>
          </div>
          <div class="form_main">
            <el-form
              label-position="top"
              :model="depositForm"
              ref="depositForm"
              status-icon
              :rules="localDepositorRules"
            >
              <div class="form-box">
                <ul class="clearfix">
                  <li>
                    <AccountNumber
                      :supportedCurrenciesList="validCurrencies"
                      @setCurrency="setCurrency"
                      @setAccountNumber="setAccountNumber"
                    ></AccountNumber>
                  </li>
                  <li>
                    <SelectForm
                      :label="$t('deposit.localdepositor.depositor')"
                      name="depositor"
                      v-model="depositForm.depositor"
                    >
                      <el-option
                        v-for="depositors in depositorsList"
                        :key="depositors.ecode"
                        :label="depositors.name"
                        :value="depositors.ecode"
                      ></el-option>
                    </SelectForm>
                  </li>
                </ul>
                <ul class="clearfix">
                  <li>
                    <el-form-item :label="setAmtLabel(accountCurrency, depositForm.amount)" prop="amount">
                      <numeric-input
                        v-model="depositForm.amount"
                        :currency="accountCurrency"
                        :precision="2"
                      ></numeric-input>
                    </el-form-item>
                  </li>
                  <li>
                    <el-form-item :label="$t('common.field.imptNotes')">
                      <el-input v-model="depositForm.notes" data-testid="imptNotes"></el-input>
                    </el-form-item>
                  </li>
                </ul>
              </div>
              <el-form-item>
                <el-button class="btn-blue" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="form-right">
        <div class="logo circle bank"></div>
      </div>
    </div>
  </DefaultTemplate>
</template>
<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import SelectForm from '@/components/form/Select';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import mixin from '@/mixins/page/deposit';
import { apiLocalDepositor_transfer, apiLocalDepositor_depositor } from '@/resource';
import rounding from '@/util/rounding';
export default {
  name: 'LocalDepositor',
  components: { NumericInput, AccountNumber, SelectForm, DefaultTemplate, DepositInfo },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: Math.ceil(this.minLimit),
              currency: this.accountCurrency
            })
          )
        );
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) > this.maxLimit) {
        callback(new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: 'USD' })));
      } else if (this.accountCurrency === 'USC' && parseFloat(value) < this.minLimitByUSC) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', { minLimit: this.minLimitByUSC, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) > this.maxLimitByUSC) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimitByUSC, currency: 'USC' }))
        );
      } else {
        callback();
      }
    };
    return {
      depositForm: {
        accountNumber: '',
        amount: '',
        depositor: '',
        notes: ''
      },
      localDepositorRules: {
        accountNumber: [
          {
            required: true,
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        depositor: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('deposit.localdepositor.depositor')
            }),
            trigger: 'blur'
          }
        ]
      },
      depositorsList: [],
      validCurrencies: ['USD', 'USC'],
      instData: [
        this.$t('deposit.localdepositor.inst1'),
        this.$t('deposit.reminder.inst1'),
        this.$t('deposit.localdepositor.inst2'),
        this.$t('deposit.localdepositor.inst3')
      ],
      showNote: false
    };
  },
  mounted() {
    this.getDepositor();
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.depositForm.accountNumber = accountNumber;
    },
    success(msg) {
      window.location = msg;
    },
    submitForm() {
      this.$refs['depositForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.code == 0 && result.data.data) {
                this.success(result.data.data);
              } else {
                this.loading = false;
                this.errorMessage(this.$t('deposit.default.failed'));
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiLocalDepositor_transfer(
        {
          mt4Account: this.depositForm.accountNumber,
          operateAmount: this.depositForm.amount,
          eCode: this.depositForm.depositor,
          applicationNotes: this.depositForm.notes,
          loyaltyRewardId: this.voucherID
        },
        this.token
      );
    },
    getDepositor() {
      return apiLocalDepositor_depositor().then(resp => {
        if (resp.data.code == 0) {
          this.depositorsList = resp.data.data;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
